import "./Purchase.css";
import Table from "../Table/Table";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";

function Purchase() {
  return (
    <section className="purchase" id="purchase">
      <ScrollToTopOnMount />
      <h2 className="purchase__title">
        Тест-системы для обнаружения патогенов методом ПЦР
      </h2>
      <div className="services__description">
        <p className="services__text">
          Наша компания предлагает к приобретению тест-системы для обнаружения
          патогенов методом ПЦР.
        </p>
        <p className="services__text">
          Тест-системы представляют собой набор реагентов для выявления
          (качественный анализ) нуклеиновых кислот (НК) фитопатогенов в
          растительном материале методом ПЦР с гибридизационно-флюоресцентной
          детекцией в реальном времени. Накопление специфического продукта ПЦР
          выявляется за счет нарастания интенсивности флуоресценции после
          освобождения флуоресцентной метки в результате разрушения
          Taq-полимеразой гибридизационного зонда, связавшегося со специфическим
          участком ДНК (кДНК).
        </p>
        <p className="services__text">
          Линейка тест-систем включает 19 наборов, предназначенных для
          обнаружения растительных инфекций, распространенных на территории
          Российской Федерации. Чтобы приобрести интересующую Вас тест-систему,
          свяжитесь с менеджером компании по электронной почте{" "}
          <a
            href="mailto:fitodiagnostika@mail.ru"
            className="purchase__link link"
          >
            fitodiagnostika@mail.ru.
          </a>{" "}
          Актуальные цены на тест-системы также уточняйте у менеджера.
        </p>
      </div>
      <Table />
    </section>
  );
}

export default Purchase;
