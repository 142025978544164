const tableInfo = [
  {
    cellOne: "Cucumber green mottle mosaic virus (CGMMV)",
    cellTwo: "Цена по запросу",
    id: "1",
  },
  {
    cellOne: "Cucumber mosaic virus (CMV)",
    cellTwo: "Цена по запросу",
    id: "2",
  },
  {
    cellOne: "Tomato mosaic virus (ToMV)",
    cellTwo: "Цена по запросу",
    id: "3",
  },
  {
    cellOne: "Tobacco mosaic virus (TMV)",
    cellTwo: "Цена по запросу",
    id: "4",
  },
  {
    cellOne: "Tomato yellow leaf curl virus (TYLCV)",
    cellTwo: "Цена по запросу",
    id: "5",
  },
  {
    cellOne: "Tomato brown rugose fruit virus (ToBRFV)",
    cellTwo: "Цена по запросу",
    id: "6",
  },
  {
    cellOne: "Pepino mosaic virus (PepMV)",
    cellTwo: "Цена по запросу",
    id: "7",
  },
  {
    cellOne: "Potato virus X (PVX)",
    cellTwo: "Цена по запросу",
    id: "8",
  },
  {
    cellOne: "Potato virus Y (PVY)",
    cellTwo: "Цена по запросу",
    id: "9",
  },
  {
    cellOne: "Fusarium oxysporum",
    cellTwo: "Цена по запросу",
    id: "10",
  },
  {
    cellOne: "Fusarium spp. ",
    cellTwo: "Цена по запросу",
    id: "11",
  },
  {
    cellOne: "Pythium spp.",
    cellTwo: "Цена по запросу",
    id: "12",
  },
  {
    cellOne: "Pseudomonas spp.",
    cellTwo: "Цена по запросу",
    id: "13",
  },
  {
    cellOne: "Clavibacter michiganensis subsp. michiganensis ",
    cellTwo: "Цена по запросу",
    id: "14",
  },
  {
    cellOne: "Potato spindel tuber viroid (PSTVd)",
    cellTwo: "Цена по запросу",
    id: "15",
  },
  {
    cellOne: "Tomato spotted wilt virus (TSWV)",
    cellTwo: "Цена по запросу",
    id: "16",
  },
  {
    cellOne: "Beet pseudo-yellows virus (BPYV)",
    cellTwo: "Цена по запросу",
    id: "17",
  },
  {
    cellOne: "Ralstonia solanacearum",
    cellTwo: "Цена по запросу",
    id: "18",
  },
  {
    cellOne: "Pseudomonas syringae",
    cellTwo: "Цена по запросу",
    id: "19",
  },
];

const pathogens = [
  {
    price: "2500,00",
    idInput: "CGМMV",
    name: "Вирус зеленой крапчатой мозаики огурца ВЗКМО (CGМMV)",
  },
  {
    price: "2500,00",
    idInput: "CMV",
    name: "Вирус обычной мозаики огурца (CMV)",
  },
  {
    price: "2500,00",
    idInput: "ZYMV",
    name: "Вирус желтой мозаики цукини (ZYMV)",
  },
  { price: "2500,00", idInput: "WMV", name: "Вирус мозаики арбуза (WMV)" },
  {
    price: "2500,00",
    idInput: "CLSV",
    name: "Вирус пятнистости листьев огурца (CLSV)",
  },
  {
    price: "2500,00",
    idInput: "CVYV",
    name: "Вирус пожелтения жилок огурца (CVYV)",
  },
  {
    price: "2500,00",
    idInput: "CABYV",
    name: "Вирус желтизны переносимый тлей (CABYV)",
  },
  {
    price: "2500,00",
    idInput: "CCYV",
    name: "Вирус хлоротичного пожелтения тыквенных (CCYV)",
  },
  {
    price: "2500,00",
    idInput: "CYSDV",
    name: "Вирус пожелтения тыквенных, вызывающий задержку роста (CYSDV)",
  },
  {
    price: "2500,00",
    idInput: "SqYV",
    name: "Вирус пожелтения жилок тыквы (SqYV)",
  },
  {
    price: "2500,00",
    idInput: "BNYVV",
    name: "Вирус некротического пожелтения жилок свеклы (BNYVV)",
  },
  {
    price: "2500,00",
    idInput: "BPYV",
    name: "Вирус псевдожелтухи свеклы (BPYV)",
  },
  { price: "2500,00", idInput: "ToMV", name: "Вирус мозаики томата (ToMV)" },
  { price: "2500,00", idInput: "TMV", name: "Вирус табачной мозаики (TMV)" },
  {
    price: "2500,00",
    idInput: "TYLCV",
    name: "Вирус желтой курчавости листьев томата (TYLCV)",
  },
  {
    price: "2500,00",
    idInput: "ToBRFV",
    name: "Вирус коричневой морщинистости плодов томата (ToBRFV)",
  },
  {
    price: "2500,00",
    idInput: "PepMV",
    name: "Вирус пепино один генотип (PepMV)",
  },
  {
    price: "6000,00",
    idInput: "PepMVх4",
    name: "Вирус пепино по пяти генотипам (PepMV х4)",
  },
  {
    price: "2500,00",
    idInput: "TSWV",
    name: "Вирус пятнистого увядания томатов (TSWV)",
  },
  { price: "2500,00", idInput: "TAV", name: "Вирус аспермии томатов (TAV)" },
  {
    price: "2500,00",
    idInput: "ToRSV",
    name: "Вирус кольцевой пятнистости томата (Tomatto ringspott virus, ToRSV)",
  },
  {
    price: "2500,00",
    idInput: "TBRV",
    name: "Вирус черного кольца томата (Tomato black ring virus, TBRV)",
  },
  { price: "2500,00", idInput: "ToCV", name: "Вирус хлороза томата (ToCV)" },
  {
    price: "2500,00",
    idInput: "TICV",
    name: "Вирус инфекционного хлороза томата (TICV)",
  },
  { price: "2500,00", idInput: "ToTV", name: "Вирус томата торрало (ToTV)" },
  {
    price: "2500,00",
    idInput: "TBSV",
    name: "Вирус кустистой карликовости томата (TBSV)",
  },
  {
    price: "2500,00",
    idInput: "TRSV",
    name: "Вирус пятнистости табака (TRSV)",
  },
  {
    price: "2500,00",
    idInput: "TMGMV",
    name: "Вирус легкой мозаики табака (TMGMV)",
  },
  {
    price: "2500,00",
    idInput: "EMDV",
    name: "Вирус карликовой пятнистости баклажанов (EMDV)",
  },
  { price: "2500,00", idInput: "ArMV", name: "Вирус мозаики резухи (ArMV)" },
  {
    price: "2500,00",
    idInput: "INSV",
    name: "Вирус некротической пятнистости бальзамина (INSV)",
  },
  {
    price: "2500,00",
    idInput: "GfMMV",
    name: "Вирус легкой пятнистости Гейфезера (GfMMV)",
  },
  { price: "2500,00", idInput: "PVX", name: "Вирус картофеля Х (PVX)" },
  { price: "2500,00", idInput: "PVY", name: "Вирус картофеля Y (PVY)" },
  { price: "2500,00", idInput: "PVA", name: "Вирус картофеля A (PVA)" },
  { price: "2500,00", idInput: "PVS", name: "Вирус картофеля S (PVS)" },
  { price: "2500,00", idInput: "PVM", name: "Вирус картофеля M (PVM)" },
  {
    price: "2500,00",
    idInput: "PLRV",
    name: "Вирус скручивания листьев картофеля (PLRV)",
  },
  {
    price: "2500,00",
    idInput: "PMTV",
    name: "Вирус метельчатости верхушки картофеля (PMTV)",
  },
];

const viroids = [
  {
    price: "2500,00",
    idInput: "PSTVd",
    name: "Вироид веретеновидности клубней картофеля (PSTVd)",
  },
  {
    price: "2500,00",
    idInput: "CPFVd",
    name: "Вироид бледноплодности огурца (CPFVd)",
  },
];

const bacteria = [
  {
    price: "2500,00",
    idInput: "Agrobacterium",
    name: "Agrobacterium sp.",
  },
  {
    price: "2500,00",
    idInput: "Acidovorax",
    name: "Acidovorax citrulli",
  },
  {
    price: "2500,00",
    idInput: "Clavibacter",
    name: "Clavibacter michiganensis",
  },
  {
    price: "2500,00",
    idInput: "Pectobacterium",
    name: "Pectobacterium (Erwinia) carotovorum",
  },
  {
    price: "2500,00",
    idInput: "Pseudomonasc",
    name: "Pseudomonas corrugata",
  },
  {
    price: "2500,00",
    idInput: "Pseudomonass",
    name: "Pseudomonas syringae",
  },
  {
    price: "2500,00",
    idInput: "Ralstonia",
    name: "Ralstonia solanacearum",
  },
  {
    price: "2500,00",
    idInput: "Xanthomonasg",
    name: "Xanthomonas gardneri",
  },
  {
    price: "2500,00",
    idInput: "Xanthomonasp",
    name: "Xanthomonas perforans",
  },
  {
    price: "2500,00",
    idInput: "Xanthomonasv",
    name: "Xanthomonas vesicatoria",
  },
];

const phytoplasmas = [
  {
    price: "2500,00",
    idInput: "PhLO",
    name: "Tomato stolbur phytoplasma (PhLO)",
  },
  {
    price: "2500,00",
    idInput: "16SrI",
    name: "Фитоплазма желтухи астры (16SrI)",
  },
];

const mushrooms = [
  {
    price: "2500,00",
    idInput: "Fusariums",
    name: "Fusarium sp.",
  },
  {
    price: "2500,00",
    idInput: "Fusariumo",
    name: "Fusarium oxysporum",
  },
  {
    price: "2500,00",
    idInput: "Botrytis",
    name: "Botrytis cinerea",
  },
  {
    price: "2500,00",
    idInput: "Pythium",
    name: "Pythium sp.",
  },
  {
    price: "2500,00",
    idInput: "Bipolaris",
    name: "Bipolaris polaris sorokiniana",
  },
  {
    price: "2500,00",
    idInput: "Verticillium",
    name: "Verticillium albo-atrum",
  },
  {
    price: "2500,00",
    idInput: "VerticilliumD",
    name: "Verticillium dahlia",
  },
  {
    price: "2500,00",
    idInput: "Didymella",
    name: "Didymella lycopersici",
  },
  {
    price: "4000,00",
    idInput: "complex",
    name: "Комплекс возбудителей Смолистого ожога стеблей (3 вида)",
  },
];

const phytopathogens = [
  {
    price: "3000,00",
    idInput: "ph_1",
    name: "Микологический посев",
  },
  {
    price: "3000,00",
    idInput: "ph_2",
    name: "Бактериологический посев (без типирования)",
  },
];

const sensitiveMushrooms = [
  {
    price: "2500,00",
    idInput: "sensitiveMushrooms",
    name: "Определение чувствительности культуры грибов к фунгицидам (за 1 препарат)",
  },
];

const nuclease = [
  {
    price: "50000,00",
    idInput: "nucl1",
    name: "Полный нуклеазный профиль (наличие РНКаз, ДНКаз, активность в диапазоне температур 37-75 С)",
  },
  {
    price: "23000,00",
    idInput: "nucl2",
    name: "Активность РНКаз и ДНКаз при 37-40 С",
  },
  {
    price: "12000,00",
    idInput: "nucl3",
    name: "Активность РНКаз/ДНКаз при 37-40 С",
  },
];

export {
  tableInfo,
  pathogens,
  viroids,
  bacteria,
  phytoplasmas,
  mushrooms,
  phytopathogens,
  sensitiveMushrooms,
  nuclease,
};
