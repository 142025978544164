import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";
import "./Services.css";
import FeedbackForm from "../FeedbackForm/FeedbackForm";

function Services() {
  return (
    <section className="services" id="services">
      <ScrollToTopOnMount />
      <h2 className="services__title">Услуги компании</h2>
      <div className="services__description">
        <p className="services__text">
          Чтобы воспользоваться услугами компании, пожалуйста, заполните
          нижележащую форму и выберете один, или несколько анализов. Если вы
          выбираете услуги по определению наличия патогенов, отберите,
          пожалуйста, растительный материал, которым должны выступать пораженные
          органы растений (листья, плоды, стебли и т.д.). Для анализа достаточно
          не более 100 г пораженной ткани.
        </p>
        <p className="services__text">
          Вы можете отправить растительный материал любым удобным способом.
          После заполнения заявки, с Вами свяжется менеджер компании для
          уточнения деталей оказываемой услуги.
        </p>
      </div>
      <FeedbackForm />
    </section>
  );
}

export default Services;
