import { useEffect, useState } from "react";

function FeedbackFormCheckboxes({ pathogen, onChange, selectedInputs }) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    function checkActiveCheckbox() {
      Object.keys(selectedInputs).includes(pathogen.name)
        ? setIsChecked(true)
        : setIsChecked(false);
    }

    checkActiveCheckbox();
  });

  return (
    <label
      key={pathogen.idInput}
      htmlFor={pathogen.idInput}
      className="feedback__label feedback__label_type_pathogen cursor"
    >
      <input
        type="checkbox"
        name={pathogen.name}
        id={pathogen.idInput}
        onChange={onChange}
        checked={isChecked}
        className="feedback__checkbox feedback__checkbox_type_pathogen"
      />
      <div className="feedback__two-columns">
        <span className="feedback__span feedback__span_type_pathogen-name">
          {pathogen.name}
        </span>
        <span className="feedback__span feedback__span_type_price">
          {`${pathogen.price}руб`}
        </span>
      </div>
    </label>
  );
}

export default FeedbackFormCheckboxes;
